import React from "react"
import SEO from "../../components/seo"
import gsap from "gsap"
import styled from "styled-components"
import SkySplashFC from "../../components/sky-splash"

const objectsDistance = 10

const Section = styled.div`
  height: 100vh;
`

const SkySplash = ({ location }) => {
  return (
    <>
      <SEO title="Hihi" />
      <SkySplashFC />
    </>
  )
}
export default SkySplash
